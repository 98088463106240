import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
/* eslint-enable */
import { Divider, SectionStyles, FooterLinkStyles } from 'aether-marketing';
// import bffFooterData from '../../../bff-data/footer.json';

import PreFooterSideBySideLottie from './PreFooterSideBySideLottie';

// const footerData = pmRr(footerDataLocal);

// For local Footer TESTING
import footerDataLocal from '../../../build/footerDev.json';
/* eslint-enable */ // root relative link

// import footerDataLocal from '../../../build/footerDev.json';

/* eslint-disable import/no-extraneous-dependencies */
// const pmRr = require('@postman/rr');
const pmUuid = require('@postman/uuid'); // absolut links

const Column = styled.ul`
  padding-left: 0;
  .footer {
    position: relative;
    overflow: hidden;
  }
`;

const ColumnRow = styled.li`
  list-style-type: none;
  margin-bottom: 0;
  padding: 5px 0;
`;

const FooterImg = styled.img`
  width: 100px !important;
  height: auto;
  margin: 0 auto;
  display: block;
`;

const SocialCol = styled.div`
  span {
    display: inline-block;
    margin: 0 8px;
  }
  img {
    opacity: 0.7;
    filter: grayscale(100%);
    transition: ${(props) => props.theme.transitions.all};
    &:hover {
      opacity: 1;
      filter: grayscale(0);
      transition: ${(props) => props.theme.transitions.all};
    }
  }
  svg {
    opacity: 0.7;
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    transition: 0.2s ease-in-out;
  }
  svg:hover {
    opacity: 1;
    filter: grayscale(0%);
    transition: 0.2s ease-in-out;
  }
`;

const Copyright = styled.span`
  color: ${(props) => props.theme.colors.grey_50} !important;
  font-size: 14px;
`;

const FooterColTitle = styled.h2`
  font-size: 1.6rem !important;
  line-height: 1.4;
  margin-bottom: 8px;
  font-family: ${(props) => props.theme.fonts.Inter};
  font-weight: 600;
  color: ${(props) => props.theme.colors.grey_50};
  font-feature-settings: 'calt' 0;
`;

const FooterWrapper = styled.footer`
  .no-select {
    user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -moz-user-select: none;
    -ms-user-select: none;
  }
`;

const SubFooter = styled.div`
  border-top: 1px solid #ccc;
  margin-top: 20px;
  padding-top: 20px;
  overflow: hidden;
  .choice {
    font-size: 14px;
    color: ${(props) => props.theme.colors.grey_50};
  }
  .choice:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.blue_70};
    // border-bottom: 1px solid ${(props) => props.theme.colors.blue_50};
  }
  .special-link:hover {
    text-decoration: none;
  }
`;

const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};
const runtime = typeof document === 'object';
// Helper function for rel attribute in link or button
export function relStringGenerator(target) {
  if (target === 'new-tab-external-company') {
    return 'noopener noreferrer';
  }
  if (target === 'new-tab-external-nofollow') {
    return 'noopener noreferrer nofollow';
  }
  if (target === 'new-tab-postman') {
    return 'noopener';
  }
  return null;
}

// Helper function for target attribute in link or button
export function targetStringGenerator(target) {
  if (
    target === 'new-tab-external-company' ||
    target === 'new-tab-external-nofollow' ||
    target === 'new-tab-postman'
  ) {
    return '_blank';
  }
  return null;
}

function Footer() {
  // createObserver();
  // Call it where needed
  // const [data] = useState(footerData);

  // FOR LOCAL TOP NAVBAR TESTING: comment in navbarDataLocal import and below
  // **************************************************************************
  const [data, setData] = useState(footerDataLocal);
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      setData(footerDataLocal); // pulls in footerData from file generated by BFF
    }
  }, []);

  const columns = data.items.slice(0, 5);
  const PreFooterData = {
    divider: false,
    layout: '1S',
    title: '',
    isDark: true,
    backgroundColor: '#210B30',
    body: [
      '<p class="subtitle mt-4"> June 3 & 4, 2025 in Los Angeles, CA</p>',
      '<p>Step into the future of APIs and AI at POST/CON 25. Join developers, architects, and tech leaders to build smarter, faster, and more secure APIs in the age of generative AI.</p>'
    ],
    logo: {
      src: 'https://voyager.postman.com/post-con/2025/postcon-25-logo.svg',
      alt: 'POST/CON 2024 Banner'
    },
    link: {
      url: 'https://postcon.postman.com/2025/',
      target: 'same-postman',
      id: 'postcon',
      ariaLabel: 'Learn more and register for POST/CON 25',
      gaCategory: 'postcon',
      gaLabel: 'postcon',
      label: 'postcon',
      text: 'Learn more and register'
    },
    media: {
      src: 'https://voyager.postman.com/post-con/2025/postcon25-prefooter-section.png',
      alt: '',
      border: '',
      isLottie: false,
      autoplay: true,
      loop: true
    }
  };

  const currentPage = runtime && window.location.pathname; // Get the current page

  const excludedRoutes = [
    '/learn/',
    '/pricing/',
    '/lp/email-unsubscribe/',
    '/lp/postman-enterprise/',
    '/lp/api-management/',
    '/lp/case-study-paypal/',
    '/lp/total-economic-impact/',
    '/lp/unlocking-ai-potential/',
    '/lp/unlocking-ai-potential-thank-you/',
    '/lp/api-security/',
    '/lp/api-first-enterprise/',
    '/platform/',
    '/publish/',
    '/events/',
    '/lp/grow-with-postman/',
    '/events/',
    '/publish/',
    '/lp/grow-with-postman-enterprise/',
    '/lp/enterprise-api-development/',
    '/lp/api-collaboration/',
    '/lp/api-documentation/',
    '/lp/api-governance/',
    '/lp/api-security-platform/',
    '/lp/api-testing-platform/',
    '/lp/api-management-platform/',
    '/lp/postman/',
    '/report/state-of-api-2024/',
    '/report/state-of-api-2024-thank-you/',
    '/platform/',
    '/platform/postman-swaggerhub/',
    '/platform/postman-insomnia/',
    '/product/ai-agent-builder/',
    '/product/ai-agent-builder/thank-you/'
  ];

  if (runtime && !excludedRoutes.includes(currentPage)) {
    setTimeout(() => {
      const selectedPreFooterSideBySideLottie = document.querySelector(
        'section[class^="PreFooterSideBySideLottie"]'
      );

      if (selectedPreFooterSideBySideLottie) {
        selectedPreFooterSideBySideLottie.style.display = 'block';
      }
    }, 1000);
  }

  return (
    <>
      <PreFooterSideBySideLottie
        backgroundColor={PreFooterData.backgroundColor}
        layout={PreFooterData.layout}
        body={PreFooterData.body}
        link={PreFooterData.link}
        title={PreFooterData.title}
        logo={PreFooterData.logo}
        // button={PreFooterData.button}
        media={PreFooterData.media}
        isDark={PreFooterData.isDark}
      />

      <Divider fullWidth />
      <FooterWrapper className="footer">
        <SectionStyles id="Footer" paddingBottom="40px">
          <div className="container small">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  {/* First column */}
                  <div className="col-12 col-md-2 col-lg-2 order-lg-first order-last my-4 my-lg-0">
                    <FooterImg
                      src="https://voyager.postman.com/logo/postman-logo-orange-stacked.svg"
                      alt={data.alt}
                      fetchpriority="low"
                      loading="lazy"
                    />
                  </div>
                  {/* Second column Product */}
                  <div className="col-6 col-md-4 col-lg-2 mb-2 mb-md-0 no-select">
                    {columns.slice(0, 1).map((item) => (
                      <nav aria-labelledby={item.arialabelledby} key={pmUuid()}>
                        <FooterColTitle id={item.arialabelledby}>
                          {item.title}
                        </FooterColTitle>
                        <Column>
                          {(item.items &&
                            item.items.map((link) => (
                              <ColumnRow key={pmUuid()}>
                                <FooterLinkStyles
                                  href={link.url}
                                  rel={relStringGenerator(link.target)}
                                  target={targetStringGenerator(link.target)}
                                  aria-label={link.ariaLabel}
                                  onClick={() => {
                                    triggerGA(link.category, link.label);
                                  }}
                                >
                                  {link.title}
                                </FooterLinkStyles>
                              </ColumnRow>
                            ))) ||
                            ''}
                        </Column>
                      </nav>
                    ))}
                  </div>
                  {/* Third Column API Network */}
                  <div className="col-6 col-md-4 col-lg-2 no-select">
                    {/* Third column - stacked - top */}
                    {columns.slice(1, 2).map((item) => (
                      <div key={pmUuid()}>
                        <nav
                          aria-labelledby={item.arialabelledby}
                          style={{ marginBottom: `${37}px` }}
                        >
                          <FooterColTitle id={item.arialabelledby}>
                            {item.title}
                          </FooterColTitle>
                          <Column>
                            {(item.items &&
                              item.items.map((link) => (
                                <ColumnRow key={pmUuid()}>
                                  <FooterLinkStyles
                                    href={link.url}
                                    rel={relStringGenerator(link.target)}
                                    target={targetStringGenerator(link.target)}
                                    aria-label={link.ariaLabel}
                                    onClick={() => {
                                      triggerGA(link.category, link.label);
                                    }}
                                  >
                                    {link.title}
                                  </FooterLinkStyles>
                                </ColumnRow>
                              ))) ||
                              ''}
                          </Column>
                        </nav>
                      </div>
                    ))}
                  </div>
                  {/* Fourth Column Resources */}
                  <div className="col-6 col-md-4 col-lg-2 no-select">
                    {columns.slice(2, 3).map((item) => (
                      <div key={pmUuid()}>
                        <nav
                          aria-labelledby={item.arialabelledby}
                          className="mb-5"
                        >
                          <FooterColTitle id={item.arialabelledby}>
                            {item.title}
                          </FooterColTitle>
                          <Column>
                            {(item.items &&
                              item.items.map((link) => (
                                <ColumnRow key={pmUuid()}>
                                  <FooterLinkStyles
                                    href={link.url}
                                    rel={relStringGenerator(link.target)}
                                    target={targetStringGenerator(link.target)}
                                    aria-label={link.ariaLabel}
                                    onClick={() => {
                                      triggerGA(link.category, link.label);
                                    }}
                                  >
                                    {link.title}
                                  </FooterLinkStyles>
                                </ColumnRow>
                              ))) ||
                              ''}
                          </Column>
                        </nav>
                      </div>
                    ))}
                  </div>
                  {/* Fifth Column Legal */}
                  <div className="col-6 col-md-4 col-lg-2 no-select">
                    {columns.slice(3, 4).map((item) => (
                      <nav aria-labelledby={item.arialabelledby} key={pmUuid()}>
                        <FooterColTitle id={item.arialabelledby}>
                          {item.title}
                        </FooterColTitle>
                        <Column>
                          {(item.items &&
                            item.items.map((link) => (
                              <ColumnRow key={pmUuid()}>
                                <FooterLinkStyles
                                  href={link.url}
                                  rel={relStringGenerator(link.target)}
                                  target={targetStringGenerator(link.target)}
                                  aria-label={link.ariaLabel}
                                  onClick={() => {
                                    triggerGA(link.category, link.label);
                                  }}
                                >
                                  {link.title}
                                </FooterLinkStyles>
                              </ColumnRow>
                            ))) ||
                            ''}
                        </Column>
                      </nav>
                    ))}
                  </div>
                  {/* Sixth Column Company */}
                  <div className="col-6 col-md-4 col-lg-2 no-select">
                    {columns.slice(4, 5).map((item) => (
                      <nav aria-labelledby={item.arialabelledby} key={pmUuid()}>
                        <FooterColTitle id={item.arialabelledby}>
                          {item.title}
                        </FooterColTitle>
                        <Column>
                          {(item.items &&
                            item.items.map((link) => (
                              <ColumnRow key={pmUuid()}>
                                <FooterLinkStyles
                                  href={link.url}
                                  rel={relStringGenerator(link.target)}
                                  target={targetStringGenerator(link.target)}
                                  aria-label={link.ariaLabel}
                                  onClick={() => {
                                    triggerGA(link.category, link.label);
                                  }}
                                >
                                  {link.title}
                                </FooterLinkStyles>
                              </ColumnRow>
                            ))) ||
                            ''}
                        </Column>
                      </nav>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 text-center text-lg-right pr-0 mt-3">
              <div className="row social">
                {data.items.slice(5, 6).map((item) => (
                  <SocialCol className="col-sm-12" key={pmUuid()}>
                    <nav aria-labelledby={item.arialabelledby} className="">
                      <Column>
                        {item.items &&
                          item.items.map((svg) => (
                            <span key={pmUuid()}>
                              <a
                                href={svg.url}
                                rel="noopener noreferrer"
                                target="_blank"
                                aria-label={svg.arialabel}
                                onClick={() => {
                                  triggerGA('social', svg.arialabel);
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                  }}
                                >
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: svg.svg
                                    }}
                                  />
                                </div>
                              </a>
                            </span>
                          ))}
                      </Column>
                    </nav>
                  </SocialCol>
                ))}
              </div>
            </div>

            <SubFooter className="subfooter">
              <div className="container pl-0 pr-0">
                <div className="row">
                  <div className="col-12 col-lg-4">
                    <div className="row">
                      <div className="col-12 col-lg-6 mb-2 mb-lg-0 text-center text-lg-left">
                        <a href="/downloads/" className="special-link">
                          Download Postman
                        </a>
                      </div>
                      <div className="col-12 col-lg-6 mb-2 mb-lg-0 text-center text-lg-left">
                        <a
                          className="choice d-inline-block"
                          href="https://www.postman.com/legal/privacy-choices/"
                        >
                          Your privacy choices
                        </a>
                        <img
                          src="https://voyager.postman.com/icon/privacyoptions.svg"
                          alt=""
                          width={24}
                          height={10}
                          className=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-8 text-center text-lg-right">
                    <Copyright>
                      &copy; {new Date().getFullYear()} Postman, Inc.
                    </Copyright>
                  </div>
                </div>
              </div>
            </SubFooter>
            {/*  eslint-enable */}
          </div>
        </SectionStyles>
      </FooterWrapper>
    </>
  );
}

export default Footer;
