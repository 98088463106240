/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
/* eslint-disable import/no-extraneous-dependencies */
import facebookNoScriptPixel from '@facebook/fb/noscript';
import linkedInNoScriptPixel from '@linkedin/li/noscript';
import PostmanPlan from '@postman/plan';
/* eslint-enable */
import packageData from '../../package.json';

const runtime = typeof document === 'object';

PostmanPlan().then((data) => {
  if (runtime) {
    window.pmt('log', [
      {
        '[@postman/plan]': data
      }
    ]);
  }
});

const { statusWidget: disallowedStatusWidget } = packageData.disallow;
const isStatusWidgetAllowed =
  runtime &&
  disallowedStatusWidget.reduce(
    (returnValue, path) =>
      (!returnValue && document.location.pathname.indexOf(path) === -1) ||
      returnValue,
    false
  );

function SEO({
  description,
  lang,
  meta,
  image: seoPreviewImg,
  title,
  url,
  canonical,
  noindex
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            default_image
            galaxy_image
            siteUrl
            assetPrefix
            tag
            beta
            log
            ot
            amp
            bng
            db
            fb
            gt
            influ2
            li
            mnt
            rd
            seg
            zm
            qlf
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaTitle = title || site.siteMetadata.title;
  const noIndex = noindex;
  const metaUrl = `${site.siteMetadata.siteUrl}${url}`;
  const statusWidget =
    (isStatusWidgetAllowed && (
      <script src="https://ms1frkqnsp7r.statuspage.io/embed/script.js" />
    )) ||
    null;
  // check if there is a custom preview image for SEO
  // Switches images for Postman and Postman Galaxy (Defaults)
  const rootDomain = site.siteMetadata.siteUrl;
  const rootPrefix = site.siteMetadata.assetPrefix;
  const regex = new RegExp(`${rootDomain}/postman-galaxy/.*`);
  const defaultImage =
    regex.test(metaUrl) === true
      ? site.siteMetadata.galaxy_image
      : site.siteMetadata.default_image;
  const defaultImageIsRootRelative =
    defaultImage && !defaultImage.match(/http/) && defaultImage.match(/^\//);
  const seoPreviewImgIsRootRelative =
    seoPreviewImg &&
    typeof seoPreviewImg === 'string' &&
    !seoPreviewImg.match(/http/) &&
    seoPreviewImg.match(/^\//);
  // Use custom preview image in json or use default set in gatsby-config.js
  let image =
    (seoPreviewImg &&
      ((seoPreviewImgIsRootRelative &&
        `${rootDomain}/${rootPrefix}/${seoPreviewImg}`) ||
        seoPreviewImg)) ||
    (defaultImageIsRootRelative &&
      `${rootDomain}/${rootPrefix}/${defaultImage}`) ||
    defaultImage;
  if (
    image &&
    typeof image === 'string' &&
    image.match(rootDomain) &&
    !image.match(rootPrefix)
  ) {
    image = image.replace(/\/assets\//, `/${rootPrefix}/assets/`);
  }
  if (typeof image === 'string') {
    if (!image.match('getpostman') && image.match('//assets')) {
      image = image.replace('//assets', '/assets');
    }
    if (image.match(`/${rootPrefix}//${rootPrefix}`)) {
      image = image.replace(`/${rootPrefix}//${rootPrefix}`, `/${rootPrefix}`);
    }
  }

  const imgIsAbsolutePostman =
    !defaultImageIsRootRelative &&
    !seoPreviewImgIsRootRelative &&
    typeof image === 'string' &&
    image.match('postman');

  if (
    site.siteMetadata.beta &&
    typeof image === 'string' &&
    image.match('postman.com') &&
    !image.match('getpostman.com') &&
    !image.match('voyager.postman')
  ) {
    image = image.replace('postman.com', 'postman-beta.com');
  }

  if (
    typeof image === 'string' &&
    (site.siteMetadata.log === '1' || site.siteMetadata.log === 'seo')
  ) {
    const icon = (imgIsAbsolutePostman && '✅') || '✓';

    /* eslint-disable no-console */
    console.log(`${icon} ${image}`);
    /* eslint-enable */
  }
  const htmlTitle = `${site.siteMetadata.tag}${title}`;

  if (runtime && window.pmt) {
    window.pmt('scalp', ['pm-analytics', 'load', document.location.pathname]);
  }

  const bngJs = `(function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"${site.siteMetadata.bng}", enableAutoSpaTracking: true};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");`;
  const dbJs = `(function(d,b,a,s,e){ var t = b.createElement(a),fs = b.getElementsByTagName(a)[0]; t.async=1; t.id=e; t.src=s;fs.parentNode.insertBefore(t, fs); })(window,document,'script','https://tag.demandbase.com/${site.siteMetadata.db}.min.js','demandbase_js_lib');`;
  const fbJs = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${site.siteMetadata.fb}');fbq('track', 'PageView');`;
  const liJs = `window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];window._linkedin_data_partner_ids.push("${site.siteMetadata.li}");(function(l) {if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};window.lintrk.q=[]}var s = document.getElementsByTagName("script")[0];var b = document.createElement("script");b.type = "text/javascript";b.async = true;b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";s.parentNode.insertBefore(b, s);})(window.lintrk);`;
  const mntJs = `(function(){"use strict";var e=null,b="4.0.0",n="${site.siteMetadata.mnt}",additional="term=value",t,r,i;try{t=top.document.referer!==""?encodeURIComponent(top.document.referrer.substring(0,2048)):""}catch(o){t=document.referrer!==null?document.referrer.toString().substring(0,2048):""}try{r=window&&window.top&&document.location&&window.top.location===document.location?document.location:window&&window.top&&window.top.location&&""!==window.top.location?window.top.location:document.location}catch(u){r=document.location}try{i=parent.location.href!==""?encodeURIComponent(parent.location.href.toString().substring(0,2048)):""}catch(a){try{i=r!==null?encodeURIComponent(r.toString().substring(0,2048)):""}catch(f){i=""}}var l,c=document.createElement("script"),h=null,p=document.getElementsByTagName("script"),d=Number(p.length)-1,v=document.getElementsByTagName("script")[d];if(typeof l==="undefined"){l=Math.floor(Math.random()*1e17)}h="dx.mountain.com/spx?"+"dxver="+b+"&shaid="+n+"&tdr="+t+"&plh="+i+"&cb="+l+additional;c.type="text/javascript";c.src=("https:"===document.location.protocol?"https://":"http://")+h;v.parentNode.insertBefore(c,v)})()`;
  const otJs = `function OptanonWrapper() { }`;
  const rdJs = `!function(w,d){if(!w.rdt){var p=w.rdt=function(){p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)};p.callQueue=[];var t=d.createElement("script");t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;var s=d.getElementsByTagName("script")[0];s.parentNode.insertBefore(t,s)}}(window,document);rdt('init','${site.siteMetadata.rd}');rdt('track', 'PageVisit');`;
  const segJs = `!function(){var i="analytics",analytics=window[i]=window[i]||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window[i].initialized)return window[i][e].apply(window[i],arguments);var n=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");n.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}n.unshift(e);analytics.push(n);return analytics}};for(var n=0;n<analytics.methods.length;n++){var key=analytics.methods[n];analytics[key]=analytics.factory(key)}analytics.load=function(key,n){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.setAttribute("data-global-segment-analytics-key",i);t.src="https://evs.cdp.postman.com/6C88CYC9Neo3CfCCSD2BHa/xae7HN2EeK3mLEpFypgqAS.min.js";var r=document.getElementsByTagName("script")[0];r.parentNode.insertBefore(t,r);analytics._loadOptions=n};analytics._writeKey="${site.siteMetadata.seg}";analytics._cdn = "https://evs.cdp.postman.com";analytics.SNIPPET_VERSION="5.2.0";}}();`;
  const zmJs = `(function(){window[(function(_zye,_8H){var _qs='';for(var _JY=0;_JY<_zye.length;_JY++){var _kz=_zye[_JY].charCodeAt();_kz!=_JY;_kz-=_8H;_8H>7;_kz+=61;_kz%=94;_kz+=33;_qs==_qs;_qs+=String.fromCharCode(_kz)}return _qs})(atob('ZlVcfnt2cW8iV3En'), 12)] = '${site.siteMetadata.zm}';     var zi = document.createElement('script');     (zi.type = 'text/javascript'),     (zi.async = true),     (zi.src = (function(_PdF,_pu){var _K8='';for(var _67=0;_67<_PdF.length;_67++){var _F3=_PdF[_67].charCodeAt();_F3-=_pu;_F3+=61;_F3%=94;_K8==_K8;_F3+=33;_F3!=_67;_pu>2;_K8+=String.fromCharCode(_F3)}return _K8})(atob('MT09OTxhVlYzPFVDMlQ8LDsyOT08VSw4NlZDMlQ9KjBVMzw='), 39)),document.readyState === 'complete'?document.body.appendChild(zi):window.addEventListener('load', function(){document.body.appendChild(zi)});})();`;
  const qlfJs = `(function(w,q){w['QualifiedObject']=q;w[q]=w[q]||function(){(w[q].q=w[q].q||[]).push(arguments)};})(window,'qualified');`;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={htmlTitle}
      titleTemplate="%s"
      link={
        canonical ? [{ rel: 'canonical', key: canonical, href: canonical }] : []
      }
      meta={[
        {
          name: 'description',
          content: metaDescription
        },
        {
          name: noIndex && 'robots',
          content: noIndex && 'noindex'
        },
        {
          property: 'og:url',
          content: metaUrl
        },
        {
          property: 'og:title',
          content: `${metaTitle}`
        },
        {
          property: 'og:site_name',
          content: site.siteMetadata.title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author
        },
        {
          name: 'twitter:title',
          content: metaTitle
        },
        {
          name: 'twitter:description',
          content: metaDescription
        },
        {
          name: 'twitter:site',
          content: site.siteMetadata.author
        }
      ]
        .concat(
          image
            ? [
                {
                  property: 'og:image',
                  content: image
                },
                {
                  property: 'twitter:image',
                  content: image
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image'
                }
              ]
            : [
                {
                  name: 'twitter:card',
                  content: 'summary'
                }
              ]
        )
        .concat(meta)}
    >
      <script data-px="<!-- px -->'">{`
        !function(){
          function px(opts, cb) {
            var e = document.createElement('script');for(key in opts){if(key!=='textContent'&&key!=='top'){e.setAttribute(key, opts[key]);}}
            e.onreadystatechange = function(){if (this.readyState === 'complete' || this.readyState === 'loaded') {if(typeof cb === 'function'){cb();}}};
            if (opts.textContent){e.textContent=opts.textContent;}e.onload = cb;
            if (opts.dnt) {
              e.removeAttribute('dnt',null);
              if(parseInt(navigator.doNotTrack)===1||parseInt(window.doNotTrack)===1||parseInt(navigator.msDoNotTrack)===1||navigator.doNotTrack==="yes"){return -1;}
            }
            if (opts.top) {
              var fpx = [...document.querySelectorAll('[data-px]')].shift();
              fpx.parentNode.insertBefore(e,fpx);
            } else {
              var lpx = [...document.querySelectorAll('[data-px]')].pop();
              lpx.parentNode.insertBefore(e,lpx.nextElementSibling);
            }
          }

          px({'data-px': '<!-- OptanonConsentNoticeEnd -->',
            dnt: true,
            top: true,
            textContent: \`${otJs}\`
          });
          px({'data-px': '<!-- OptanonConsentNoticeStart -->',
            dnt: true,
            top: true,
            type: 'text/javascript',
            charset: 'UTF-8',
            'data-domain-script': '${site.siteMetadata.ot}',
            src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
          },()=>{
            px({'data-px': '<!-- Bing -->',
              dnt: true,
              async: true,
              textContent: \`${bngJs}\`
            });

            px({'data-px': '<!-- DemandBase -->',
              dnt: true,
              async: true,
              textContent: \`${dbJs}\`
            });

            px({'data-px': '<!-- Facebook -->',
              dnt: true,
              async: true,
              textContent: \`${fbJs}\`
            });

            px({'data-px': '<!-- GTag -->',
              dnt: true,
              async: true,
              src: '/mkapi/gtag.js'
            }, function(){
              var gt1 = '${site.siteMetadata.gt.split('|').shift()}';
              var gt2 = '${site.siteMetadata.gt.split('|').pop()}';
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());
              window.gtag = gtag;
              window.gtag('config', gt1);
              window.gtag('config', gt2);
              window.pmt('log', ['[GTag] config: ' + gt1]);
              window.pmt('log', ['[GTag] config: ' + gt2]);
            });

            px({'data-px': '<!-- InFlu2 -->',
              dnt: true,
              async: true,
              src: 'https://www.influ2.com/tracker?clid=${
                site.siteMetadata.influ2
              }'
            });

            px({'data-px': '<!-- LinkedIn -->',
              dnt: true,
              async: true,
              textContent: \`${liJs}\`
            });

            px({'data-px': '<!-- MetaData -->',
              dnt: true,
              async: true,
              src: '/mkapi/md.js'
            });

            px({'data-px': '<!-- Mountain -->',
              dnt: true,
              async: true,
              textContent: \`${mntJs}\`
            });

            px({'data-px': '<!-- RampMetrics -->',
              dnt: true,
              async: true,
              src: '/mkapi/rm.js'
            });

            px({'data-px': '<!-- Reddit -->',
              dnt: true,
              async: true,
              textContent: \`${rdJs}\`
            });

            px({'data-px': '<!-- ZoomInfo -->',
              dnt: true,
              async: true,
              textContent: \`${zmJs}\`
            });

            if (window.location.host !== 'localhost:8000') {
              px({'data-px': '<!-- Qualified -->',
                dnt: true,
                async: true,
                textContent: \`${qlfJs}\`
              });
              px({'data-px': '<!-- Qualified:cdn -->',
                dnt: true,
                async: true,
                src: 'https://js.qualified.com/qualified.js?token=${
                  site.siteMetadata.qlf
                }'
              });
            }
          });

          px({'data-px': '<!-- CDP -->',
              textContent: \`${segJs}\`
            });
        }();
      `}</script>
      <link rel="preconnect" href="https://voyager.postman.com" crossOrigin />
      <link
        href="https://voyager.postman.com/font/fonts.css"
        rel="stylesheet"
      />
      <noscript>{`<link href="https://voyager.postman.com/font/fonts.css" rel="stylesheet" type="text/css" />`}</noscript>
      <noscript>{`<link href="${facebookNoScriptPixel}" />`}</noscript>
      <noscript>{`<link href="${linkedInNoScriptPixel}" />`}</noscript>
      {statusWidget}
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
  url: '',
  image: {},
  noindex: false
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  noindex: PropTypes.bool,
  canonical: PropTypes.string.isRequired,
  image: PropTypes.string
};

export default SEO;
